/* ==========================================================================
   #PACK
   ========================================================================== */

/**
 * The pack object simply causes any number of elements pack up horizontally to
 * automatically fill an equal, fluid width of their parent.
 *
 * 1. Fill all available space.
 * 2. Remove any leftover styling from lists.
 * 3. Cause children to be automatically equally sized.
 *
 * Example:
 *  Create a barebones navigation bar by combining it with o-listBare.
 *
<ul class="list  o-pack">
  <li class="o-pack-item"><a href="#">Home</a></li>
  <li class="o-pack-item"><a href="#">About</a></li>
  <li class="o-pack-item"><a href="#">Portfolio</a></li>
  <li class="o-pack-item"><a href="#">Contact</a></li>
</ul>
 *
 */

.o-pack {
  width: 100%; /* [1] */
  margin-left: 0; /* [2] */
  display: table;
  table-layout: fixed; /* [3] */
}

/**
 * 1. Cause children to adopt table-like structure.
 * 2. Default item alignment is with the tops of each other.
 */

.o-pack-item {
  display: block;

  @include breakpointUp(small) {
    display: table-cell; // [1]
    vertical-align: top; // [2]
  }


  /**
   * Modifiers: Vertical alignment variants.
   */

  .o-pack--middle > & {
    vertical-align: middle;
  }

  .o-pack--bottom > & {
    vertical-align: bottom;
  }

}

/**
 * Modifier: Unequal-width items.
 */

.o-pack--auto {
  table-layout: auto;
}

/* Modifier: Size variants.
   ========================================================================== */

.o-pack--smallest {
  border-spacing: $spacing-smallest;
}

.o-pack--smaller {
  border-spacing: $spacing-smaller;
}

.o-pack--small {
  border-spacing: $spacing-small;
}

.o-pack--large {
  border-spacing: $spacing-large;
}

.o-pack--larger {
  border-spacing: $spacing-larger;
}

/* Modifier: Reversed order.
   ========================================================================== */

// .o-pack--reverse {
//   direction: rtl;
//
//   > .o-pack-item {
//     direction: ltr;
//   }
//
// }
