/**
 * Support old IE?
 *
 * This is mainly for a fallback pixel value for the rem unit. But this can
 * be used for many things.
 */

$supportOldIE: false;

/**
 * Settings
 *
 * Global variables
 */

@import "1-settings/colors";
@import "1-settings/typography";
@import "1-settings/spacing";
@import "1-settings/breakpoints";
@import "1-settings/animation";
@import "1-settings/opacity";
@import "1-settings/radius";
@import "1-settings/shadows";
@import "1-settings/z-index";

/**
 * Tools
 *
 * Mixins and functions
 */

@import "2-tools/function-em-rem";
@import "2-tools/function-lineheight";
@import "2-tools/mixin-em-rem";
@import "2-tools/mixin-button-variant";
@import "2-tools/mixin-font-size";
@import "2-tools/mixin-clearfix";
@import "2-tools/mixin-breakpoint";
@import "2-tools/mixin-vertical-align";
@import "2-tools/mixin-triangle";
@import "2-tools/mixin-bb-icon-font";

/**
 * Generic
 *
 * Resets and normalize.
 */

@import "3-generic/normalize";
@import "3-generic/reset";
@import "3-generic/shared";
// @import "3-generic/print";

/**
 * Elements
 *
 * Default, unclassed elements
 */

@import "4-elements/page";
@import "4-elements/typography";
@import "4-elements/links";
@import "4-elements/lists";
@import "4-elements/tables";
@import "4-elements/forms";
@import "4-elements/images";
@import "4-elements/code";

/**
 * Objects
 *
 * Non cosmetic, structural classes. Helps reduce duplicate code.
 */

@import "5-objects/wrapper";
@import "5-objects/grid";
// @import "5-objects/grid-float"; // Not needed if using flexbox grid
@import "5-objects/box";
// @import "5-objects/flex";
@import "5-objects/list-reset";
@import "5-objects/list-inline";
// @import "5-objects/nav";
@import "5-objects/pack";
// @import "5-objects/media";
@import "5-objects/ratio";

/**
 * Components
 *
 * Damn near everything else. Fully styled UI modules, sections, etc.
 */

@import "6-components/bb-icons";
@import "6-components/feature-icon";
@import "6-components/rule";
@import "6-components/button";
@import "6-components/cta";
@import "6-components/table";
@import "6-components/list";
@import "6-components/loader";
@import "6-components/tooltip";
@import "6-components/forms";
@import "6-components/hero";
@import "6-components/site-header";
@import "6-components/site-navigation";
//@import "6-components/product-column";
@import "6-components/modal";
@import "6-components/tabs";

/**
 * Utils
 *
 * Utility helper classes.
 */

@import "7-utils/bg-size";
@import "7-utils/borders";
@import "7-utils/colors";
@import "7-utils/links";
@import "7-utils/cursor";
@import "7-utils/flex";
@import "7-utils/hovers";
@import "7-utils/layout";
@import "7-utils/margin";
@import "7-utils/opacity";
@import "7-utils/padding";
@import "7-utils/position";
@import "7-utils/push";
@import "7-utils/shadows";
@import "7-utils/size";
@import "7-utils/text";
@import "7-utils/transitions";
@import "7-utils/vertical-align";
@import "7-utils/display";

/**
 * Shame
 *
 * Hacks and filth go in this file.
 */

@import "shame";
