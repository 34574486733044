/* ==========================================================================
 * Border radius
 * --------------------------------------------------------------------------
 * Various sizes for border radius.
 * ========================================================================== */

$borderRadius-base: 3px !default;
$borderRadius-100: 100% !default;





// ==========================================================================
// IF NEEDED
// ==========================================================================

$borderRadius-lg:       5px !default;
$borderRadius-xl:       8px !default;
$borderRadius-xxl:      12px !default;
$borderRadius-xxxl:     30px !default;
