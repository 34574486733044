// loader
.loader {
  border-radius: 500px;
  border: 3px solid $color-grayLight;
  border-left-color: $color-primary;
  display: inline-block;
  vertical-align: middle;
  height: 35px;
  width: 35px;
}

.loader--doubleBars {
  border-left-color: $color-primary;
  border-right-color: $color-primary;
}

.loader--fill {
  border-color: $color-primary;
}

.loader--lg {
  width: 50px;
  height: 50px;
}

.loader--sm {
  width: 25px;
  height: 25px;
  border-width: 2px;
}

.loader--xsm {
  width: 15px;
  height: 15px;
  border-width: 2px;
}

// Animations
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.is-spinning {
  animation-name: spin;
  animation-duration: 700ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    transform: scale(.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.is-pulsing {
  animation-name: pulse;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}
